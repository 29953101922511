<template>
  <div class="fill-height d-flex flex-column">

    <ToolbarTop
      :loading="loading"
      :mini="mini"
      :school-subjects="schoolSubjects"
      :filter-by-school-subject="filterBySchoolSubject"
      @input:filter-by-school-subject="setFilterBySchoolSubject"
      @input:context-object="setContextObject"
      @input:date-interval="setDateInterval"
    />

    <StickyHeaderList class="fill-height pa-2 pa-sm-4 pa-lg-8">
      <StickyHeaderListItem
        v-for="week in itemsByWeeks"
        :key="week.weekId"
      >
        <template v-slot:sticky-header>
          <span class="d-flex">
            {{ week.text }}
            <small class="ml-1">
              ({{ week.startsOn | formatDate }} - {{ week.endsOn | formatDate }})
            </small>
          </span>
        </template>

        <v-lazy
          v-for="(value, key) in week.items"
          :key="key"
          min-height="76"
        >
          <WeekReportCard
            v-bind="value"
            @edit="showEditWeekReportDialog($event)"
          />
        </v-lazy>
      </StickyHeaderListItem>
    </StickyHeaderList>

    <ToolbarBottom
      v-if="mini"
      :loading="loading"
      @input:date-interval="setDateInterval"
    />

    <EditWeekReportDialog
      :value="editWeekReportDialogValue"
      @close="editWeekReportDialogValue = null"
    />

    <AppLoader v-show="loading" />

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import apiClient from '@/api-client'
import AppLoader from '@/components/app-loader'
import ToolbarTop from '@/pages/week-reports.page/toolbar-top'
import ToolbarBottom from '@/pages/week-reports.page/toolbar-bottom'
import StickyHeaderList from '@/components/sticky-header-list'
import StickyHeaderListItem from '@/components/sticky-header-list/item'
import WeekReportCard from '@/pages/week-reports.page/week-report-card'
import EditWeekReportDialog from '@/components/edit-week-report-dialog'
import { formatDate } from '@/helpers/datetime'

export default {
  name: 'WeekReportsPage',
  components: {
    AppLoader,
    ToolbarTop,
    ToolbarBottom,
    StickyHeaderList,
    StickyHeaderListItem,
    WeekReportCard,
    EditWeekReportDialog
  },
  filters: {
    formatDate
  },
  data: () => ({
    loadingActualLessons: null,
    actualLessons: [],
    filterBySchoolSubject: null,
    editWeekReportDialogValue: null
  }),
  computed: {
    ...mapState(['contextObject']),
    ...mapGetters('schoolCalendar', ['weeksByDates']),
    ...mapState('schoolCalendar', [
      'academicYear',
      'section',
      'startDate',
      'endDate'
    ]),
    loading() {
      return this.loadingActualLessons || this.$store.getters['common/loading']
    },
    mini() {
      return this.$vuetify.breakpoint.xsOnly
    },
    showWelcomePage() {
      const state = this.$store.state.common
      return state.academicYears.length == 0
        || state.sections.length == 0
        || state.schoolSubjects.length == 0
    },
    itemsByWeeks() {
      const startDate = this.startDate || this.section?.startsOn
      const endDate = this.endDate || this.section?.endsOn
      return this.weeksByDates(startDate, endDate).map((week) => {
        const items = {}
        const filterBySchoolSubject = this.filterBySchoolSubject?.['@id']
        this.actualLessons
          .filter(({ date }) => week.startsOn <= date && date <= week.endsOn)
          .forEach((al) => {
            const schoolSubject = al.getSchoolSubject()?.['@id']
            if (!schoolSubject || filterBySchoolSubject && filterBySchoolSubject !== schoolSubject) {
              return
            }
            const contextObject = al.scheduledLesson?.course || al.scheduledLesson?.schoolClass
            const { weekId } = week
            const key = `${contextObject}:${schoolSubject}:${weekId}`
            if (!(key in items)) {
              items[key] = {contextObject, schoolSubject, weekId}
            }
          })
        return {...week, items}
      }).filter((w) => Object.keys(w.items).length > 0)
    },
    schoolSubjects() {
      return [...new Set(this.actualLessons?.map(al => al.getSchoolSubject()).filter(s => !!s))]
    }
  },
  async created() {
    const { dispatch } = this.$store
    await this.loadCommonData()
    if (this.showWelcomePage) {
      this.$router.push({name: 'welcome'})
      return
    }
    await dispatch('schoolCalendar/setNearestAcademicYear')
    await dispatch('schoolCalendar/setNearestSection')
    await Promise.all([
      dispatch('common/fetchSchoolClassesByAcademicYear', this.academicYear),
      dispatch('common/fetchCoursesBySection', this.section),
    ])
    await dispatch('schoolCalendar/setNearestSchoolWeek')
    dispatch('resetContextObject', ['SchoolClass', 'Course'])
    this.loadActualLessons()
  },
  methods: {
    loadCommonData() {
      return this.$store.dispatch('common/fetchCollectionOnce', [
        'academicYears',
        'schoolSubjects',
        'sections',
      ])
    },
    async loadActualLessons() {
      this.actualLessons = []
      const { section, contextObject } = this
      if (!(section && contextObject)) return
      const startDate = this.startDate || section.startsOn
      const endDate = this.endDate || section.endsOn
      const criteria = {startDate, endDate}
      const options = {full: true, includeScheduledLesson: true}
      this.loadingActualLessons = true
      try {
        this.actualLessons = await apiClient.actualLessons.findByContextObject(contextObject, criteria, options)
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingActualLessons = false
      }
    },
    async setDateInterval(value) {
      const { commit, dispatch } = this.$store
      const { academicYear, section } = value || {}
      const promises = []
      if (academicYear && academicYear.year !== this.academicYear?.year) {
        promises.push(
          dispatch('common/fetchSchoolClassesByAcademicYear', academicYear)
        )
      }
      if (section && section['@id'] !== this.section?.['@id']) {
        promises.push(
          dispatch('common/fetchDayPlansBySection', section),
          dispatch('common/fetchCoursesBySection', section)
        )
      }
      commit('schoolCalendar/SET_STATE', value)
      await Promise.all(promises)
      dispatch('resetContextObject')
      this.loadActualLessons()
    },
    setContextObject(contextObject) {
      this.$store.commit('SET_CONTEXT_OBJECT', contextObject)
      this.loadActualLessons()
    },
    setFilterBySchoolSubject(schoolSubject) {
      this.filterBySchoolSubject = schoolSubject
    },
    showEditWeekReportDialog(value) {
      this.editWeekReportDialogValue = value
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/scss/variables.scss';

.title {
  font-size: 14px !important;
  background: $default-school-subject-color;
  cursor: default;
}
</style>
