<template>
  <v-card class="mt-4 pa-1">
    <v-card-title class="flex-nowrap">
      <SchoolSubjectChip :school-subject="schoolSubject" />
      <v-spacer />
      <v-btn
        v-if="canWriteWeekReport"
        icon
        @click="onClickEdit"
      >
        <v-icon>mdi-lead-pencil</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="text">
      <LessonTextField
        label="Bericht"
        :value="text"
      />
    </v-card-text>
  </v-card>
</template>


<script>
import apiClient from '@/api-client'
import SchoolSubjectChip from '@/components/school-subject-chip'
import LessonTextField from '@/components/lesson-text-field'

export default {
  name: 'WeekReportCard',
  components: {
    SchoolSubjectChip,
    LessonTextField
  },
  props: {
    contextObject: {
      type: String,
      required: true
    },
    schoolSubject: {
      type: String,
      required: true
    },
    weekId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    weekReport: null
  }),
  computed: {
    canWriteWeekReport() {
      return this.schoolSubject && this.$store.getters['canWriteWeekReport']
    },
    text() {
      return this.weekReport?.text
    }
  },
  created() {
    this.loadWeekReport()
  },
  methods: {
    async loadWeekReport() {
      const { contextObject, schoolSubject, weekId: calendarWeekCode } = this
      if (!(contextObject && schoolSubject && calendarWeekCode)) return
      try {
        this.weekReport = await apiClient.weekReports.findOneBy({contextObject, schoolSubject, calendarWeekCode})
      } catch (e) {
        console.error(e)
      }
    },
    onClickEdit() {
      this.$emit('edit', {
        contextObject: this.contextObject,
        schoolSubject: this.schoolSubject,
        calendarWeekCode: this.weekId,
        done: () => this.loadWeekReport()
      })
    }
  }
}
</script>
