<template>
  <v-dialog
    persistent
    scrollable
    max-width="1024"
    :value="!!value"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card v-if="value">

      <v-card-title class="subtitle-1 justify-center">

        <v-icon class="mr-4">mdi-clipboard-text-outline</v-icon>

        <div class="text-sm-h6">Wochenbericht</div>

        <div class="week-title flex-grow-1">
          {{ week.text }}
          <small>
            ({{ week.startsOn | formatDate }} - {{ week.endsOn | formatDate }})
          </small>
        </div>

        <SchoolSubjectChip :school-subject="this.value?.schoolSubject" />

      </v-card-title>

      <v-divider/>

      <v-card-text class="pt-4">
        <v-textarea
          auto-grow
          outlined
          dense
          rows="4"
          counter="1000"
          :disabled="loading || saving"
          :rules="[length(1000)]"
          label="Bericht"
          v-model="text"
        />
      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          text
          :disabled="loading || saving"
          v-text="hasChanges ? 'Abbrechen' : 'Schließen'"
          @click="$emit('close')"
        />
        <v-btn
          v-show="hasChanges"
          color="primary"
          :disabled="loading"
          :loading="saving"
          @click="save"
        >
          Schließen
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters } from 'vuex'
import apiClient from '@/api-client'
import { formatDate } from '@/helpers/datetime'
import { length } from '@/helpers/validators'
import SchoolSubjectChip from '@/components/school-subject-chip'

export default {
  name: 'EditWeekReportDialog',
  components: {
    SchoolSubjectChip
  },
  filters: {
    formatDate
  },
  props: {
    value: {
      type: Object
    },
  },
  data: () => ({
    length,
    weekReport: null,
    text: '',
    loading: false,
    saving: false,
    error: null
  }),
  computed: {
    ...mapGetters('schoolCalendar', ['weeksBySection']),
    week() {
      const { section } = this.$store.state.schoolCalendar
      const weeks = this.$store.getters['schoolCalendar/weeksBySection'](section)
      const weekId = this.value?.calendarWeekCode
      return weeks.find(w => w.weekId === weekId)
    },
    hasChanges() {
      const originalText = this.weekReport?.text || ''
      return !!this.value && this.text !== originalText
    }
  },
  watch: {
    value() {
      Object.assign(this, {
        weekReport: null,
        text: '',
        loading: false,
        saving: false,
        error: null
      })
      if (this.value) {
        this.loadWeekReport()
      }
    },
    weekReport() {
      this.text = this.weekReport?.text || ''
    }
  },
  methods: {
    async loadWeekReport() {
      const { contextObject, schoolSubject, calendarWeekCode } = this.value || {}
      if (!(contextObject && schoolSubject && calendarWeekCode)) return
      this.loading = true
      try {
        this.weekReport = await apiClient.weekReports.findOneBy({
          contextObject,
          schoolSubject,
          calendarWeekCode,
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async save() {
      if (!this.hasChanges) {
        return
      }
      const weekReport = this.weekReport || {
        contextObject: this.value.contextObject,
        schoolSubject: this.value.schoolSubject,
        calendarWeekCode: this.value.calendarWeekCode,
      }
      this.error = null
      this.saving = true
      try {
        await apiClient.weekReports.upsert({...weekReport, text: this.text})
        if (this.value.done) {
          this.value.done()
        }
        this.$emit('close')
      } catch (e) {
        console.error(e)
        this.error = apiClient.getErrorReason(e)
      } finally {
        this.saving = false
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.week-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 16px;
  font-size: small;
}
</style>
